import React from 'react'
import Logo from '../../assets/Logo1BG.png'
import YoutubeTip from './YoutubeTip'
import InstagramTip from './InstagramTip'
import TelegramTip from './TelegramTip'
import { Link } from 'react-router-dom'

const Footer = () => {
    const links = [
        {
            title: 'Клиентам',
            items: [
                {
                    label: 'Вход',
                    url: 'https://app.bb-data.pro/login',
                    external: true
                },
                {
                    label: 'Регистрация',
                    url: 'https://app.bb-data.pro/signup',
                    external: true
                }
            ]
        },
        {
            title: 'Документы',
            items: [
                {
                    label: 'Публичная оферта',
                    url: '/docs/offerta',
                    external: false
                },
                {
                    label: 'Использование cookie',
                    url: '/docs/cookie',
                    external: false
                }
            ]
        }
    ]

    return (
        <div className='xl:flex xl:bg-[#eaf3fd] xl:mt-20 justify-center'>
            <footer className='bg-[#eaf3fd] xl:px-10 ss:px-10 mt-12 xl:w-full md:mt-24 px-4 md:px-20 flex max-w-[1920px] flex-col gap-10 xl:pt-0 pt-14 pb-10'>
                <div className='flex flex-col gap-14 xl:flex-row justify-between'>
                    <div className='flex flex-col gap-7'>
                        <div className='flex flex-col gap-4'>
                            <img src={Logo} alt='logo' className='max-w-[200px] ss:max-w-[300px] md:max-w-[400px]' />
                            <p className='max-w-[300px] md:text-lg text-gray-500'>
                                Аналитика и оцифровка продаж <span className='text-purple-600'>Wildberries</span> и <span className='text-blue-600'>Ozon</span>
                            </p>
                        </div>
                        <div className='flex gap-6'>
                            <a href='https://t.me/asdy10'>
                                <TelegramTip />
                            </a>
                        </div>
                    </div>
                    <div className='flex flex-wrap gap-7 md:flex-row xl:gap-40 ss:flex-wrap justify-between'>
                        {links.map((section, index) => (
                            <div key={index} className='flex mr-20 ss:mr-0 flex-col gap-2 md:gap-4'>
                                <h3 className='text-xl md:text-2xl font-bold text-black'>{section.title}</h3>
                                <div className='flex flex-col gap-1 md:gap-3'>
                                    {section.items.map((item, itemIndex) => (
                                        item.external ? (
                                            <a
                                                key={itemIndex}
                                                href={item.url}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                className="relative w-fit active:scale-95 duration-300 transition-all inline-block text-gray-500 text-base md:text-2xl after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[1px] after:w-0 after:bg-gray-500 after:transition-all after:duration-500 hover:after:w-full"
                                            >
                                                {item.label}
                                            </a>
                                        ) : (
                                            <Link
                                                key={itemIndex}
                                                to={item.url}
                                                className="relative w-fit active:scale-95 duration-300 transition-all inline-block text-gray-500 text-base md:text-2xl after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-[1px] after:w-0 after:bg-gray-500 after:transition-all after:duration-500 hover:after:w-full"
                                            >
                                                {item.label}
                                            </Link>
                                        )
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='flex justify-between'>
                    <div className='flex flex-col gap-2'>
                        <span className='text-base md:text-xl text-gray-500'>Самозанятый Ермолаев Алексей Кириллович</span>
                        <span className='text-base md:text-xl text-gray-500'>ИНН: 027319606592</span>
                    </div>
                    <div></div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
